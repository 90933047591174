<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')" id="tech-service-all" no-body>

    <template v-if="edit">
      <Form/>
    </template>

    <template v-else-if="docs.classificator">
      <Classificator/>
    </template>

    <template v-else-if="docs.financial">
      <Financial/>
    </template>

    <template v-else-if="docs.fuel">
      <Fuel/>
    </template>

    <template v-else-if="docs.info">
      <Info/>
    </template>

    <template v-else-if="docs.reportView">
      <ReportView/>
    </template>

    <template v-else-if="docs.individual">
      <Individual/>
    </template>

    <template v-else-if="docs.company">
      <Company/>
    </template>

    <template v-else-if="docs.entrepreneur">
      <Entrepreneur/>
    </template>

    <template v-else-if="docs.count">
      <Count/>
    </template>

    <template v-else-if="docs.amounts">
      <Amounts/>
    </template>

    <template v-else-if="docs.operators">
      <Operators/>
    </template>

    <template v-else>
      <b-row class="pageContent">
        <b-col class="filtersContainer" md="12">
          <div class="filters">

            <div v-if="!$device.isMobile" class="filter-column">
              <v-select
                  @input="changeCompany"
                  placeholder="Որոնում"
                  id="vue-select-type"
                  :reduce="prefix => prefix.id"
                  v-model="filters.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="search_filters"
                  class="searchTypeSelect"
                  :clearable="false"
              />

            </div>

            <div v-if="$device.isMobile" class="filter-column">
              <b-dropdown
                  size="sm"
                  text=""
                  variant="flat-primary"
                  id="docs_selector"
              >
                <b-dropdown-item @click="changeSearchTypeMobile(item.id)" v-for="item in search_filters">
                  {{item.label}}
                </b-dropdown-item>

              </b-dropdown>
            </div>

            <div class="filter-column">
              <b-form-input
                  autocomplete="off"
                  @change="searchCheckup"
                  @keydown="disableSymbol($event)"
                  v-model="filters.search"
                  id="owner_name"
                  type="text"
                  placeholder="Փնտրել..."
                  class="w-full text-uppercase opinionFilter"
                  clearable
              />
            </div>

            <div class="filter-column">
              <v-select
                  autocomplete="new_owner_name"
                  @input="changeCompany"
                  placeholder="Կազմակերպություն"
                  id="vue-select"
                  :reduce="prefix => prefix.id"
                  v-model="filters.company"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="companies"
                  class="companyFilterSelect"
              />

            </div>

            <div class="filter-column">
              <div @click="toggleDoubleCheckup" :class="(filters.double) ? 'double-checkup active' : 'double-checkup'">Կրկնակի</div>
            </div>

            <div class="filter-column">
              <div @click="getRemovedItems" :class="(filters.deleted) ? 'double-checkup active' : 'double-checkup'">Հեռացված</div>
            </div>

            <div class="filter-column">
              <div @click="getTaxDebtItems" :class="(filters.taxDebt) ? 'double-checkup active' : 'double-checkup'">Գույքահարկ</div>
            </div>

          </div>
        </b-col>
        <b-col md="12">
          <ag-grid-vue
              @gridReady="onGridReady"
              ref="agGridTable"
              :localeText="localization"
              :components="components"
              :gridOptions="gridOptions"
              :class="table_class"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="checkups"
              rowSelection="multiple"
              colResizeDefault="shift"
              :animateRows="true"
              :floatingFilter="true"
              :pagination="true"
              :paginationPageSize="50"
              :suppressPaginationPanel="true"
              :enableRtl="$vs.rtl">
          </ag-grid-vue>
          <b-card-body class="table-footer d-flex justify-content-between flex-wrap pt-0 pb-0 align-items-center">

            <!-- page length -->
            <strong class="black">
              {{ pagination.total }} արդյունք
            </strong>

            <!-- pagination -->
            <div>
              <b-pagination
                  @input="changePage"
                  v-model="pagination.current_page"
                  :total-rows="pagination.total"
                  :per-page="pagination.count"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-col>

        <table style="display: none" id="exportTable">
          <thead>
          <tr>
            <th v-if="!isHide(column.id)" :colspan="column.children.length" v-for="column in techServiceColumns">{{column.headerName}}</th>
          </tr>
          <tr>
            <template v-for="column in techServiceColumns">
              <th v-if="!isHide(item.id)" v-for="item in column.children">{{item.headerName}}</th>
            </template>
          </tr>
          </thead>
          <tbody>
          <tr v-for="checkup of export_checkups">

            <td v-if="!isHide('001')">{{(checkup.Opinion) ? 'Ստուգված է' : 'Անսարք է'}}</td>
            <td v-if="!isHide('002')">{{checkup.createdAt | formatDate}}</td>
            <td v-if="!isHide('003')">{{checkup.car}} {{checkup.car_model}}</td>
            <td v-if="!isHide('004')">{{checkup.CarRegNo}}</td>
            <td v-if="!isHide('005')">{{checkup.documentNumber}}</td>
            <td v-if="!isHide('006')">{{checkup.CheckNo}}</td>
            <td v-if="!isHide('007')">{{checkup.body}}</td>
            <td v-if="!isHide('008')">{{checkup.color}}</td>
            <td v-if="!isHide('009')">{{nextCheckupFormatter({value: checkup.NextCheckup})}}</td>

            <td v-if="!isHide('011')">{{ (checkup.createdBy) ? checkup.createdBy.name : ''}}</td>
            <td v-if="!isHide('012')">{{checkup.company.name}}</td>
            <td v-if="!isHide('013')">{{companyAddressFormatter({value: checkup.company})}}</td>

            <td v-if="!isHide('021')">{{checkup.ownerStatus.Owner}}</td>
            <td v-if="!isHide('022')">{{checkup.ownerStatus.Notes}}</td>
            <td v-if="!isHide('023')">{{checkup.Phone}}</td>
            <td v-if="!isHide('024')">{{checkup.region}}</td>
            <td v-if="!isHide('025')">{{checkup.county}}</td>
            <td v-if="!isHide('026')">{{checkup.street}}</td>
            <td v-if="!isHide('027')">{{checkup.address}}</td>
            <td v-if="!isHide('028')">{{checkup.documentNumber}}</td>

            <td v-if="!isHide('031')">{{checkup.car_group}}</td>
            <td v-if="!isHide('032')">{{checkup.car_type}}</td>
            <td v-if="!isHide('033')">{{checkup.car}} {{checkup.car_model}}</td>
            <td v-if="!isHide('034')">{{checkup.car}}</td>
            <td v-if="!isHide('035')">{{checkup.car_model}}</td>
            <td v-if="!isHide('036')">{{checkup.MadeAt}}</td>
            <td v-if="!isHide('037')">{{checkup.CarRegNo}}</td>
            <td v-if="!isHide('038')">{{checkup.fuel}}</td>
            <td v-if="!isHide('039')">{{checkup.body}}</td>
            <td v-if="!isHide('0391')">{{checkup.color}}</td>
            <td v-if="!isHide('0392')">{{checkup.VIN}}</td>
            <td v-if="!isHide('0393')">{{checkup.EngineNo}}</td>
            <td v-if="!isHide('0394')">{{checkup.TrilerNo}}</td>
            <td v-if="!isHide('0395')">{{checkup.BodyNo}}</td>
            <td v-if="!isHide('0396')">{{checkup.GasNo}}</td>
            <td v-if="!isHide('0397')">{{checkup.GasDate | formatDay}}</td>

            <td v-if="!isHide('041')">{{ (checkup.Changes) ? 'Առկա է' : 'Առկա չէ'}}</td>
            <td v-if="!isHide('042')">{{checkup.ChangesNotes}}</td>

            <td v-if="!isHide('051')">{{checkup.CheckNo}}</td>
            <td v-if="!isHide('052')">{{checkup.CheckNotes}}</td>
            <td v-if="!isHide('053')">{{checkup.EkoDate | formatDay}}</td>
            <td v-if="!isHide('054')">{{(checkup.Opinion) ? 'Սարքին է' : 'Անսարք է'}}</td>
            <td v-if="!isHide('055')">{{checkup.OpinionNotes}}</td>
            <td v-if="!isHide('056')">{{nextCheckupFormatter({value: checkups.NextCheckup})}}</td>
            <td v-if="!isHide('057')">{{(checkup.DoubleChecked) ? 'Այո' : 'Ոչ' }}</td>

            <td v-if="!isHide('061')">{{checkup.EkoNo}}</td>
            <td v-if="!isHide('062')">{{checkup.EkoSum}}</td>
            <td v-if="!isHide('063')">{{checkup.Sum}}</td>
          </tr>
          </tbody>
        </table>

      </b-row>
    </template>

    <b-modal
        id="modal-add-c1-number"
        :no-close-on-backdrop="true"
        centered
        size="sm"
        title="Կտրոնի կրկնօրինակ"
        :is-valid="false"
    >

      <b-form>
        <b-form-group>

          <div class="position-relative table-responsive">
            <table role="table" aria-busy="false" aria-colcount="7" class="table b-table table-striped table-hover"
                   id="__BVID__139"><!----><!---->
              <thead role="rowgroup" class=""><!---->
              <tr role="row" class="">
                <th role="columnheader" scope="col" aria-colindex="2" class="">
                  <div>Ամսաթիվ</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Կտրոնի համար</div>
                </th>
              </tr>
              </thead>
              <tbody role="rowgroup"><!---->

              <tr v-for="num in checkupNumbers" role="row" class="">
                <td aria-colindex="6" role="cell" class="">{{ num.createdAt | formatDate }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ num.number }}</td>
              </tr>
              </tbody>
            </table>
          </div>


        </b-form-group>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        <span variant="info">&nbsp;</span>
      </template>


    </b-modal>

    <b-modal
        id="modal-checkup-history"
        :no-close-on-backdrop="true"
        centered
        size="lg"
        class="modal-responsive"
        title="Պատմություն"
        :is-valid="false"
    >

      <b-form>
        <b-form-group>

          <div class="position-relative table-responsive">
            <table role="table" aria-busy="false" aria-colcount="7" class="table mb-2 b-table table-striped table-hover"
                   id="__BVID__140"><!----><!---->
              <thead role="rowgroup" class=""><!---->
              <tr role="row" class="">
                <th role="columnheader" scope="col" aria-colindex="2" class="">
                  <div>Ամսաթիվ</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Մոդերատոր</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Գործողույուն</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Նախկին տվյալ</div>
                </th>
                <th role="columnheader" scope="col" aria-colindex="3" class="">
                  <div>Նոր տվյալ</div>
                </th>
              </tr>
              </thead>
              <tbody role="rowgroup"><!---->

              <tr v-for="history in checkupHistory" role="row" class="">
                <td aria-colindex="6" role="cell" class="">{{ history.createdAt | formatDate }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ (history.user) ? history.user.name : '' }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ history.note }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ history.old }}</td>
                <td aria-colindex="7" role="cell" class=""> {{ history.new }}</td>
              </tr>
              </tbody>
            </table>
          </div>


        </b-form-group>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        <span variant="info">&nbsp;</span>
      </template>


    </b-modal>

  </b-card-code>
</template>

<script>

import techServiceModule from '@/store/tech-service/techServiceModule'
import companyModule from '@/store/companies/companyModule'
import store from '@/store'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BAlert
} from 'bootstrap-vue'
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
import Form from '@/views/pages/tech-service/Form'
import CellRendererOpinion from '@/views/pages/tech-service/columns/CellRendererOpinion'
import CellRendererChanges from '@/views/pages/tech-service/columns/CellRendererChanges'
import CellRendererCanEdit from '@/views/pages/tech-service/columns/CellRendererCanEdit'
import CellRendererCanEditOnepay from '@/views/pages/tech-service/columns/CellRendererCanEditOnepay'
import CellRendererEdit from '@/views/pages/tech-service/columns/CellRendererEdit'
import CellRendererAttachment from '@/views/pages/tech-service/columns/CellRendererAttachment'
import AG_GRID_LOCALE_HY from '@core/global/ag-grid-hy'
import vSelect from 'vue-select'
import Classificator from '@/views/pages/tech-service/docs/Classificator'
import Financial from '@/views/pages/tech-service/docs/Financial'
import Fuel from '@/views/pages/tech-service/docs/Fuel'
import Info from '@/views/pages/tech-service/docs/Info'
import ReportView from "@/views/pages/tech-service/docs/ReportView";
import Individual from '@/views/pages/tech-service/docs/Individual'
import Entrepreneur from '@/views/pages/tech-service/docs/Entrepreneur'
import Company from '@/views/pages/tech-service/docs/Company'
import Count from '@/views/pages/tech-service/docs/Count'
import Amounts from '@/views/pages/tech-service/docs/Amounts'
import Operators from '@/views/pages/tech-service/docs/Operators'
import carsModule from '@/store/cars/cars'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import CellRendererStatus from '@/views/pages/tech-service/columns/CellRendererStatus'
import CellRendererPhone from '@/views/pages/tech-service/columns/CellRendererPhone'

export default {
  name: 'tech-service-all',

  components: {
    vSelect,
    BCardCode,
    AgGridVue,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BTable,
    BAlert,
    Form,
    CellRendererOpinion,
    CellRendererChanges,
    CellRendererCanEdit,
    CellRendererCanEditOnepay,
    CellRendererEdit,
    CellRendererAttachment,
    Classificator,
    Financial,
    Fuel,
    Info,
    ReportView,
    Individual,
    Entrepreneur,
    Company,
    Count,
    Amounts,
    Operators,
    CellRendererStatus,
    CellRendererPhone,
  },

  data() {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      COMPANIES_STORE_MODULE_NAME: 'companiesModule',
      CARS_STORE_MODULE_NAME: 'carsModule',
      gridOptions: {
        getRowStyle: (params) => {
          if (params.data.CheckNoEdited) {
            return { 'background-color': '#ffc107' }
          }
          if (params.data.deleted) {
            return { 'background-color': '#afafaf' }
          }
        }
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      components: null,
      opinions: [
        {
          label: 'Սարքին',
          id: true
        },
        {
          label: 'Անսարք',
          id: false
        }
      ]
    }
  },

  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    let todayStart = new Date()
    todayStart.setHours(0, 0, 0, 0)

    let todayEnd = new Date()
    todayEnd.setHours(23, 59, 59, 59)

    const initialFilters = {
      Opinion: true,
      startDate: todayStart,
      endDate: todayEnd,
      search: '',
      company: '',
      fields: {}
    }

    return {
      skin,
      isDark,
      initialFilters
    }
  },

  computed: {

    search_filters() {
      return [
        {
          label: 'Գրանցման համարանիշի',
          id: 'CarRegNo'
        },
        {
          label: 'Գրանցման փաստաթղթի',
          id: 'documentNumber'
        },
        {
          label: 'Նույնականացման VIN կոդի',
          id: 'VIN'
        },
        // {
        //   label: 'Շարժիչի համարի',
        //   id: 'EngineNo'
        // },
        // {
        //   label: 'Հենասարքի համարի',
        //   id: 'TrilerNo'
        // },
        // {
        //   label: 'Թափքի համարի',
        //   id: 'BodyNo'
        // }
      ]
    },

    checkupNumbers() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkupNumbers
    },

    checkupHistory() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkupHistory
    },

    techServiceColumns () {
      return this.$store.state.techServiceColumns
    },

    columnStatuses() {
      return this.$store.state.columnStatuses
    },

    isHide () {
      return (id) => {
        let index = this.columnStatuses.findIndex((e) => e.id === id)
        if (index >= 0) {
          return this.columnStatuses[index].hide
        }
        return true
      }
    },

    columnDefs() {
      return [
        {
          id: '00',
          headerName: 'Տեխզննում',
          children: [
            {
              id: '001',
              hide: this.isHide('001'),
              headerName: 'Կարգավիճակ',
              field: 'createdAt',
              width: 320,
              cellRendererFramework: 'CellRendererCanEdit',
            },
            {
              headerName: 'Գույքահարկ',
              field: 'inspectionStatus',
              filter: true,
              width: 180,
              cellRendererFramework: 'CellRendererStatus'
            },
            {
              headerName: 'ԴԱՀԿ',
              field: 'dahk_exists',
              filter: true,
              width: 150,
              cellRendererFramework: 'CellRendererStatus'
            },
            {
              id: '002',
              hide: this.isHide('002'),
              headerName: 'Ստեղծվել է',
              field: 'createdAt',
              filter: true,
              floatingFilterComponent: 'customDateFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'createdAt',
                className: ''
              },
              width: 150,
              valueFormatter: this.dateFormatter
            },
            {
              headerName: '📁',
              field: 'attachment',
              filter: false,
              width: 30,
              cellRendererFramework: 'CellRendererAttachment',
            },
            {
              headerName: '📷',
              field: 'camera',
              filter: false,
              width: 30,
              cellRendererFramework: 'CellRendererAttachment',
            },
            {
              id: '003',
              hide: this.isHide('003'),
              headerName: 'Մեքենա',
              field: 'ownerStatus.Owner',
              width: 180,
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car',
                className: ''
              },
              valueFormatter: this.carFormatter
            },
            {
              id: '004',
              hide: this.isHide('004'),
              headerName: 'Համարանիշ',
              field: 'CarRegNo',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'CarRegNo',
                className: 'text-uppercase'
              },
              width: 150,
              valueFormatter: this.carRegNoFormatter
            },
            {
              id: '005',
              hide: this.isHide('005'),
              headerName: 'Փաստաթուղթ',
              field: 'documentNumber',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'documentNumber',
                className: 'text-uppercase'
              },
              width: 140,
              valueFormatter: this.uppercaseFormatter
            },
            {
              headerName: 'Շարժիչ',
              field: 'engine',
              filter: false,
              width: 140,
            },
            {
              id: '006',
              hide: this.isHide('006'),
              headerName: 'Նույնացման (VIN) համարը',
              field: 'VIN',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'VIN',
                className: 'text-uppercase'
              },
              width: 210,
            },
            {
              id: '009',
              hide: this.isHide('009'),
              headerName: 'Հաջորդ Տ․Զ․',
              field: 'NextCheckup',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'NextCheckup',
                className: '',
                options: [
                  {
                    value: 0,
                    label: '6 ամսից'
                  },
                  {
                    value: 1,
                    label: '1 տարուց'
                  },
                  {
                    value: 2,
                    label: '2 տարուց'
                  }
                ]
              },
              width: 160,
              valueFormatter: this.nextCheckupFormatter
            },
            // {
            //   id: '007',
            //   hide: this.isHide('007'),
            //   headerName: 'Թափքի տեսակ',
            //   field: 'body',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'body',
            //     className: ''
            //   },
            //   width: 150,
            //   valueFormatter: this.bodyNameFormatter,
            // },
            // {
            //   id: '008',
            //   hide: this.isHide('008'),
            //   headerName: 'Գույն',
            //   field: 'color',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'color',
            //     className: ''
            //   },
            //   width: 180,
            // }
          ]
        },
        {
          id: '01',
          headerName: 'Տեխնիկական զննության կայան',
          children: [
            {
              id: '011',
              hide: this.isHide('011'),
              headerName: 'Օպերատոր',
              field: 'createdBy.name',
              width: 210,
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'createdBy',
                className: '',
                options: this.company_operators
              },
            },
            {
              id: '012',
              hide: this.isHide('012'),
              headerName: 'Անվանում',
              field: 'company.name',
              width: 210,
            },
            {
              id: '013',
              hide: this.isHide('013'),
              headerName: 'Տ․Զ․Կ հասցե',
              field: 'company',
              valueFormatter: this.companyAddressFormatter,
              width: 210,
            },
          ]
        },
        {
          id: '02',
          headerName: 'Տրանսպորտային միջոցի տնօրինող',
          children: [
            {
              id: '021',
              hide: this.isHide('021'),
              headerName: 'Տնօրինող',
              field: 'ownerStatus.Owner',
              width: 200,
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'ownerStatus.Owner',
                className: ''
              },
            },
            {
              id: '022',
              hide: this.isHide('022'),
              headerName: 'ՀՎՀՀ',
              field: 'ownerStatus.Notes',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'ownerStatus.Notes',
                className: ''
              },
              width: 130,
            },
            {
              id: '023',
              hide: this.isHide('023'),
              headerName: 'Հեռախոս',
              field: 'Phone',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'Phone',
                className: ''
              },
              width: 180,
              cellRendererFramework: 'CellRendererPhone'
            },
            {
              id: '023',
              hide: this.isHide('023'),
              headerName: 'Հեռախոս(ՃՈ)',
              field: 'mobile',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'mobile',
                className: ''
              },
              width: 130,
            },
            {
              id: '024',
              hide: this.isHide('024'),
              headerName: 'Մարզ',
              field: 'region',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'region',
                className: ''
              },
              width: 180,
            },
            {
              id: '025',
              hide: this.isHide('025'),
              headerName: 'Քաղաք',
              field: 'county',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'county',
                className: ''
              },
              width: 180,
            },
            {
              id: '026',
              hide: this.isHide('026'),
              headerName: 'Փողոց',
              field: 'street',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'street',
                className: ''
              },
              width: 210,
            },
            {
              id: '027',
              hide: this.isHide('027'),
              headerName: 'Հասցե',
              field: 'address',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'address',
                className: ''
              },
              width: 150,
            },
            {
              id: '028',
              hide: this.isHide('028'),
              headerName: 'Գրանցման փաստաթուղթ',
              field: 'documentNumber',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'documentNumber',
                className: 'text-uppercase'
              },
              width: 210,
              valueFormatter: this.uppercaseFormatter
            },
          ]
        },
        {
          id: '03',
          headerName: 'Տրանսպորտային միջոց',
          children: [
            {
              id: '031',
              hide: this.isHide('031'),
              headerName: 'Խումբ',
              field: 'car_group',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car_group',
                className: ''
              },
              width: 210,
            },
            {
              id: '032',
              hide: this.isHide('032'),
              headerName: 'Տեսակ',
              field: 'car_type',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car_type',
                className: ''
              },
              width: 210,
            },
            {
              id: '033',
              hide: this.isHide('033'),
              headerName: 'Մեքենա',
              field: 'car',
              valueFormatter: this.carFormatter,
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car',
                className: ''
              },
              width: 210,
            },
            {
              id: '034',
              hide: this.isHide('034'),
              headerName: 'Մակնիշ',
              field: 'car',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car',
                className: ''
              },
              width: 210,
            },
            {
              id: '035',
              hide: this.isHide('035'),
              headerName: 'Մոդել',
              field: 'car_model',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'car_model',
                className: ''
              },
              width: 210,
            },
            {
              id: '036',
              hide: this.isHide('036'),
              headerName: 'Թողարկման տարեթիվ',
              field: 'MadeAt',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'MadeAt',
                className: ''
              },
              width: 210,
            },
            {
              id: '036',
              hide: this.isHide('036'),
              headerName: 'Վազք',
              field: 'vazq',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'vazq',
                className: ''
              },
              width: 210,
              valueFormatter: this.vazqFormatter
            },
            {
              id: '037',
              hide: this.isHide('037'),
              headerName: 'Գրանցման համարանիշ',
              field: 'CarRegNo',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'CarRegNo',
                className: 'text-uppercase'
              },
              width: 210,
              valueFormatter: this.carRegNoFormatter
            },
            {
              id: '038',
              hide: this.isHide('038'),
              headerName: 'Վառելիքի տեսակ',
              field: 'fuel',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'fuel',
                className: ''
              },
              width: 210,
            },
            // {
            //   id: '039',
            //   hide: this.isHide('039'),
            //   headerName: 'Թափքի տեսակ',
            //   field: 'body',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'body',
            //     className: ''
            //   },
            //   width: 210,
            //   valueFormatter: this.bodyNameFormatter,
            // },
            // {
            //   id: '0391',
            //   hide: this.isHide('0391'),
            //   headerName: 'Գույն',
            //   field: 'color',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'color',
            //     className: ''
            //   },
            //   width: 210,
            // },
            {
              id: '0392',
              hide: this.isHide('0392'),
              headerName: 'Նույնացման (VIN) համարը',
              field: 'VIN',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'VIN',
                className: 'text-uppercase'
              },
              width: 210,
            },
            // {
            //   id: '0393',
            //   hide: this.isHide('0393'),
            //   headerName: 'Շարժիչի համար',
            //   field: 'EngineNo',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'EngineNo',
            //     className: ''
            //   },
            //   width: 210,
            // },
            // {
            //   id: '0394',
            //   hide: this.isHide('0394'),
            //   headerName: 'Հենասարքի համար',
            //   field: 'TrilerNo',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'TrilerNo',
            //     className: ''
            //   },
            //   width: 210,
            // },
            // {
            //   id: '0395',
            //   hide: this.isHide('0395'),
            //   headerName: 'Թափքի համար',
            //   field: 'BodyNo',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'BodyNo',
            //     className: ''
            //   },
            //   width: 210,
            // },
            // {
            //   id: '0396',
            //   hide: this.isHide('0396'),
            //   headerName: 'Գազ․ վեր․ ակտ N',
            //   field: 'GasNo',
            //   filter: true,
            //   floatingFilterComponent: 'customFloatingFilter',
            //   floatingFilterComponentParams: {
            //     suppressFilterButton: true,
            //     field: 'GasNo',
            //     className: ''
            //   },
            //   width: 210,
            // },
            {
              id: '0397',
              hide: this.isHide('0397'),
              headerName: 'Տրված է',
              field: 'GasDate',
              filter: true,
              floatingFilterComponent: 'customDateFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'GasDate',
                className: ''
              },
              width: 210,
              valueFormatter: this.dayFormatter
            },
          ]
        },
        {
          id: '04',
          headerName: 'Կառուցվածքային փոփոխություն',
          children: [
            {
              id: '041',
              hide: this.isHide('041'),
              headerName: 'Առկա է',
              field: 'Changes',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'Changes',
                className: '',
                options: [
                  {
                    value: true,
                    label: 'Առկա է'
                  },
                  {
                    value: false,
                    label: 'Առկա չէ'
                  }
                ]
              },
              width: 115,
              cellRendererFramework: 'CellRendererChanges'
            },
            {
              id: '042',
              hide: this.isHide('042'),
              headerName: 'Փոփոխություն',
              field: 'ChangesNotes',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'ChangesNotes',
                className: ''
              },
              width: 210,
            },
          ]
        },
        {
          id: '05',
          headerName: 'Տեխնիկական զննություն',
          children: [
            {
              id: '051',
              hide: this.isHide('051'),
              headerName: 'Կտրոնի համար',
              field: 'CheckNo',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'CheckNo',
                className: ''
              },
              width: 210,
            },
            {
              id: '052',
              hide: this.isHide('052'),
              headerName: 'Նշումներ',
              field: 'CheckNotes',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'CheckNotes',
                className: ''
              },
              width: 210,
            },
            {
              id: '053',
              hide: this.isHide('053'),
              headerName: 'ՏԶ կտրոնի տրման ամսաթիվ',
              field: 'EkoDate',
              filter: true,
              floatingFilterComponent: 'customDateFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'EkoDate',
                className: ''
              },
              width: 210,
              valueFormatter: this.dayFormatter
            },
            {
              id: '054',
              hide: this.isHide('054'),
              headerName: 'Եզրակացություն',
              field: 'Opinion',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'Opinion',
                className: '',
                options: [
                  {
                    value: true,
                    label: 'Սարքին'
                  },
                  {
                    value: false,
                    label: 'Անսարք'
                  }
                ]
              },
              width: 180,
              cellRendererFramework: 'CellRendererOpinion'
            },
            {
              id: '055',
              hide: this.isHide('055'),
              headerName: 'Պատճառը',
              field: 'OpinionNotes',
              filter: true,
              floatingFilterComponent: 'customFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'OpinionNotes',
                className: ''
              },
              width: 210,
            },
            {
              id: '056',
              hide: this.isHide('056'),
              headerName: 'Հաջորդ Տ․Զ․',
              field: 'NextCheckup',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'NextCheckup',
                className: '',
                options: [
                  {
                    value: 0,
                    label: '6 ամսից'
                  },
                  {
                    value: 1,
                    label: '1 տարուց'
                  },
                  {
                    value: 2,
                    label: '2 տարուց'
                  }
                ]
              },
              width: 160,
              valueFormatter: this.nextCheckupFormatter
            },
            {
              id: '057',
              hide: this.isHide('057'),
              headerName: 'Կրկնակի Տ․Զ․',
              field: 'DoubleChecked',
              filter: true,
              floatingFilterComponent: 'customSelectFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'DoubleChecked',
                className: '',
                options: [
                  {
                    value: true,
                    label: 'Այո'
                  },
                  {
                    value: false,
                    label: 'Ոչ'
                  },
                ]
              },
              width: 140,
              cellRendererFramework: 'CellRendererChanges'
            },
            {
              id: '001',
              hide: this.isHide('001'),
              headerName: 'Ստուգում',
              field: 'onepaySynced',
              width: 180,
              cellRendererFramework: 'CellRendererCanEditOnepay',
            },
            {
              id: '002',
              hide: this.isHide('002'),
              headerName: 'ԱՊՊԱ',
              field: 'insuranceCompany',
              filter: true,
              floatingFilterComponent: 'customDateFloatingFilter',
              floatingFilterComponentParams: {
                suppressFilterButton: true,
                field: 'insuranceCompany',
                className: ''
              },
              width: 400,
              valueFormatter: this.insuranceFormatter
            }
          ]
        },
        // {
        //   id: '06',
        //   headerName: 'Վճարումներ',
        //   children: [
        //     // {
        //     //   id: '061',
        //     //   hide: this.isHide('061'),
        //     //   headerName: 'Բն․ անդ․ N',
        //     //   field: 'EkoNo',
        //     //   filter: true,
        //     //   floatingFilterComponent: 'customFloatingFilter',
        //     //   floatingFilterComponentParams: {
        //     //     suppressFilterButton: true,
        //     //     field: 'EkoNo',
        //     //     className: ''
        //     //   },
        //     //   width: 210,
        //     // },
        //     {
        //       id: '062',
        //       hide: this.isHide('062'),
        //       headerName: 'Գումար',
        //       field: 'EkoSum',
        //       filter: true,
        //       floatingFilterComponent: 'customFloatingFilter',
        //       floatingFilterComponentParams: {
        //         suppressFilterButton: true,
        //         field: 'EkoSum',
        //         className: ''
        //       },
        //       width: 210,
        //     },
        //     {
        //       id: '063',
        //       hide: this.isHide('063'),
        //       headerName: 'Տ․Զ․ վճար',
        //       field: 'Sum',
        //       filter: true,
        //       floatingFilterComponent: 'customFloatingFilter',
        //       floatingFilterComponentParams: {
        //         suppressFilterButton: true,
        //         field: 'Sum',
        //         className: ''
        //       },
        //       width: 210,
        //     }
        //   ]
        // }
      ]
    },

    company_operators() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company_operators.map(e => ({
        label: e.name,
        value: e._id,
      }))
    },

    edit() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].edit
    },

    bodies() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].bodies
    },

    docs() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].docs
    },

    checkups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkups
    },

    export_checkups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].export_checkups
    },

    pagination() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].pagination
    },

    filters: {
      get () {
        return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].filters
      },
      set (data) {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_INITIAL_FILTERS', data)
      }
    },

    syncedEnabled() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].syncedEnabled;
    },

    table_class() {
      return (this.isDark) ? 'tech-service-table ag-theme-alpine-dark w-100 ag-grid-table' : 'tech-service-table ag-theme-alpine w-100 ag-grid-table'
    },

    localization() {
      return AG_GRID_LOCALE_HY
    },

    companies() {
      return [
        {
          label: 'Կազմակերպություն',
          value: null,
          disabled: true
        },
        ...this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies.map(e => ({
          label: e.name,
          id: e._id
        }))
      ]
    },

    ownerStatuses() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].ownerStatuses
    },

    nextCheckups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].nextCheckups
    },

  },

  beforeMount() {
    this.components = {
      customFloatingFilter: this.getCustomFloatingFilterComponent(),
      customDateFloatingFilter: this.getCustomDateFloatingFilterComponent(),
      customSelectFloatingFilter: this.getCustomSelectFloatingFilterComponent(),
    }
  },

  mounted() {
  },

  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.COMPANIES_STORE_MODULE_NAME)) store.registerModule(this.COMPANIES_STORE_MODULE_NAME, companyModule)
    if (!store.hasModule(this.CARS_STORE_MODULE_NAME)) store.registerModule(this.CARS_STORE_MODULE_NAME, carsModule)
    this.getCheckups()
    this.getOperators()
    this.getCompanies()
    this.getBodies()
    this.filters = this.initialFilters
  },

  destroyed() {
    this.filters = this.initialFilters
  },

  methods: {

    toggleDoubleCheckup() {
      this.filters.double = !this.filters.double;
      this.getCheckups()
    },

    getRemovedItems () {
      if (!this.filters.deleted) {
        this.filters.deleted = true;
      } else {
        delete this.filters.deleted;
      }
      this.getCheckups()
    },

    getTaxDebtItems () {
      if (!this.filters.taxDebt) {
        this.filters.taxDebt = true;
      } else {
        delete this.filters.taxDebt;
      }
      this.getCheckups()
    },

    getOperators() {
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getOperators')
    },

    getBodies() {
      return this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getBodies')
    },

    getCompanies() {
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/getCompanies')
    },

    onGridReady(params) {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_GRID_API', params.api)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_GRID_COLUMN_API', params.columnApi)
    },

    getCheckups() {
      this.$vs.loading()
      const filters = JSON.parse(JSON.stringify(this.filters))
      const { fields } = filters
      delete filters.fields
      filters.columnFilter = JSON.stringify(fields)
      const filterData = {
        current_page: this.pagination.current_page,
        ...filters
      }
      if (this.syncedEnabled) {
        filterData.onepayExists = true;
      }
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getAllCheckups', this.serialize(filterData))
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
            this.$vs.loading.close()
          })
    },

    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },

    changePage(page) {
      this.pagination.current_page = page
      this.getCheckups()
    },

    disableSymbol(e) {
      if (!(/^[*a-zA-Z0-9ղցվա]*$/.test(e.key))) {
        e.preventDefault()
      }
    },

    changeCompany() {
      this.getCheckups()
    },

    searchCheckup() {
      this.getCheckups()
    },

    ownerStatusFormatter(params) {
      const index = this.ownerStatuses.findIndex((i) => i.id === params.value)
      if (index >= 0) {
        return this.ownerStatuses[index].title
      }
      return ''
    },

    companyAddressFormatter(params) {
      return params.value.region + ' ' + params.value.county + ' ' + params.value.street + ' ' + params.value.address
    },

    carFormatter(params) {
      return params.data.car + ' ' + params.data.car_model
    },

    carRegNoFormatter(params) {
      return `${params.data.CarRegNo} ${(params.data.YellowCarRegNo) ? `(${params.data.YellowCarRegNo.toLocaleUpperCase()})` : ''}`
    },

    vazqFormatter(params) {
      return (params.data.vazq) ? `${params.data.vazq} ${params.data.vazqType === 1 ? 'կմ' : 'մղոն'}` : '-'
    },

    bodyNameFormatter(params) {
      if (params.value) {
        return params.value
      } else {
        let bodyIndex = this.bodies.findIndex((b) => b.id === params.data.body_id)
        if (bodyIndex >= 0) {
          return this.bodies[bodyIndex].name
        }
      }
    },

    uppercaseFormatter(params) {
      return (params.value) ? params.value.toLocaleUpperCase() : params.value
    },

    dateFormatter(params) {
      return this.$options.filters.formatDate(params.value)
    },

    insuranceFormatter(params) {
      return `${params.data.insuranceCompany} - ${this.$options.filters.formatDay(params.data.insuranceEndDate)}`
    },

    dayFormatter(params) {
      return this.$options.filters.formatDay(params.value)
    },

    nextCheckupFormatter(params) {
      const index = this.nextCheckups.findIndex((e) => e.value == params.value)
      if (index >= 0) {
        return this.nextCheckups[index].label
      }
      return params.value
    },

    appendFilter(field, value) {
      this.filters.fields[field] = value
      this.getCheckups()
    },

    appendDateFilter(field, value) {
      let start = new Date(value)
      start.setHours(0, 0, 0, 0)
      let end = new Date(value)
      end.setHours(23, 59, 59, 59)
      if (field === 'createdAt') {
        this.filters.startDate = start
        this.filters.endDate = end
      }
      this.getCheckups()
    },

    getCustomFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        this.eGui.innerHTML = '<input class="ag-input-field-input ag-text-field-input ' + params.className + '" type="text" />'
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('input')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },

    getCustomSelectFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        let select = '<select class="ag-input-field-input ag-text-field-input ' + params.className + '">'
        select += '<option value=""></option>'
        if (params.options && params.options.length > 0) {
          params.options.map((item) => {
            select += `<option value="${item.value}">${item.label}</option>`
          })
        }
        select += '</select>'
        this.eGui.innerHTML = select
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('select')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },

    getCustomDateFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        this.eGui.className = 'w-100'
        this.eGui.innerHTML = '<input data-date-format="DD/MM/YYYY" class="ag-input-field-input ag-text-field-input ' + params.className + '" type="date" />'
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('input')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendDateFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },

  }

}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#tech-service-all {
  .tech-service-table {
    max-height: calc(var(--vh, 1vh) * 100 - 13rem);
    min-height: calc(var(--vh, 1vh) * 100 - 13rem);
    height: calc(var(--vh, 1vh) * 100 - 13rem);
    padding: 0.5rem;
  }

  .table-footer {
    padding-bottom: 10px !important;
  }
}
.double-checkup {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  padding: 0 13px;
  cursor: pointer;
  height: 100%;
  &.active {
    background: #2e2e2e;
    color: #fff;
  }
}
</style>
