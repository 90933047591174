<template>
  <div class="d-flex justify-content-center align-items-center h-100">
    <fa-icon v-if="params.value" @click="openModal()" class="mr-1 cursor-pointer" icon="camera"/>
    <b-modal
        :id="`modal-attachment-${params.column.colId}-${params.data._id}`"
        :no-close-on-backdrop="true"
        centered
        size="lg"
        title="Լուսանկար"
        :is-valid="false"
        :hide-footer="true"
    >
      <b-form class="d-flex justify-content-center align-items-center" v-if="params.value">
        <img :src="attachmentUrl" />
      </b-form>
    </b-modal>
  </div>
</template>

<script>

import techServiceModule from '@/store/tech-service/techServiceModule'
import store from '@/store'
import {BForm, BModal} from "bootstrap-vue";

export default {
  name: 'cell-renderer-can-edit',
  components: {BForm, BModal},
  data() {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      selectedCheckupId: null,
      selectedCheckup: null,
      selectedCheckupNumbers: [],
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    attachmentUrl() {
      let url = process.env.VUE_APP_API_URL;
      url += '/' + this.params.value;
      console.log(url);
      return url;
    }
  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
  },
  methods: {
    openLink(link) {
      window.open(link, '_blank')
    },
    openModal() {
      // eslint-disable-next-line no-underscore-dangle
      this.$bvModal.show(`modal-attachment-${this.params.column.colId}-${this.params.data._id}`)
    },
  },
}
</script>
