<template>
  <div>
    <div class="radarIcon" :class="syncedColor(params)"><span></span></div>
    <b-badge style="width: 100%" :class="badgeColClass" :variant="color(params)">
      <template v-if="params.data.onepaySyncedDate">
        {{ params.data.onepaySyncedDate | formatDate}}
      </template>
      <template v-else>
        &nbsp;
      </template>
    </b-badge>
  </div>

</template>

<script>

import { BBadge } from 'bootstrap-vue'
import VueCountdown from '@chenfengyuan/vue-countdown';
import store from '@/store'
import techServiceModule from '@/store/tech-service/techServiceModule'

export default {
  name: 'cell-renderer-can-edit',
  components: {
    BBadge,
    VueCountdown
  },
  data() {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
    }
  },
  computed: {

    userType () {
      return atob(this.$store.state.user_type)
    },

    color () {
      return (item) => {
        if (item.data.onepayExists === true) {
          return 'success'
        }
        else if (item.data.onepayExists === false) {
          return 'danger'
        } else {
          return 'warning'
        }

      }
    },

    syncedColor () {
      return (item) => {
        if (item.data.onepaySynced === true) {
          return 'success'
        } else if (item.data.onepaySynced === false) {
          return 'danger'
        } else {
          return 'warning'
        }
      }
    },

    can_edit () {
      return (item) => {
        const last_day = new Date(item.value)
        if(item.data.Opinion) {
          if (this.userType === 'TECHNICAL_SERVICE_OPERATOR') {
            const day = (item.data.Opinion) ? 1 : 20
            if (day === 20) {
              last_day.setDate(last_day.getDate() + day)
            } else {
              last_day.setMinutes(last_day.getDate() + 10)
            }
            if (day === 20) {
              last_day.setHours(23, 59, 59, 59)
            }
          }
          else {
            last_day.setMinutes(last_day.getMinutes() + 2)
          }
        }
        else {
          if (this.userType === 'TECHNICAL_SERVICE_OPERATOR' || this.userType === 'ADMIN' || this.userType === 'VIEWER') {
            const day = (item.data.Opinion) ? 1 : 20
            if (day === 20) {
              last_day.setDate(last_day.getDate() + day)
            } else {
              last_day.setMinutes(last_day.getDate() + 10)
            }
            if (day === 20) {
              last_day.setHours(23, 59, 59, 59)
            }
          }
          else {
            last_day.setMinutes(last_day.getMinutes() + 2)
          }
        }


        const now = new Date(Date.now());
        let diff =(last_day.getTime() - now.getTime()) / 1000
        diff /= 60
        return (diff > 0)
      }
    },

    editContent () {
      return (value) => {
        return (!this.can_edit(value)) ? (value.data.Opinion) ? 'Հաստատվել է' : 'Ժամկետանց' :  this.$options.filters.formatDate(value);
      }
    },

    expire_time () {
      return (item) => {
        const last_day = new Date(item.value)
        const day = (item.data.Opinion) ? 1 : 20
        if (day === 20) {
          last_day.setDate(last_day.getDate() + day)
        } else {
          last_day.setMinutes(last_day.getDate() + 10)
        }

        if (day === 20) {
          // last_day.setHours(23, 59, 59, 59)
        }
        const now = new Date(Date.now());
        let diff =(last_day.getTime() - now.getTime())
        return diff
      }
    },

    badgeColClass () {
      return 'badgeCol'
    }
  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
  },
  methods: {
    openClassificatorDoc (data) {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SELECTED_CHECKUP', data)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'classificator')
    },
    editItem(params) {
      const data = params.data
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_EDIT_FORM', data)
    },
    removeItem(params) {
      this.$swal({
        title: 'Հեռացնե՞լ',
        text: '',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Այո',
        cancelButtonText: 'Ոչ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/removeCheckup', params.data)
                  .then((response) => {
                    // this.$socket.emit('OPERATOR_REMOVED_CHECKUP', response.data.item)
                  })
                  .catch(() => {
                    this.$swal({
                      title: 'Գործողությունը մերժվել է',
                      text: '',
                      icon: 'error',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                  })
            }
          })
    },
    removeItemSoft(params) {
      this.$swal({
        title: 'Հեռացնե՞լ',
        text: '',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Այո',
        cancelButtonText: 'Ոչ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/removeCheckupSoft', params.data)
                  .then((response) => {
                    // this.$socket.emit('OPERATOR_REMOVED_CHECKUP', response.data.item)
                  })
                  .catch(() => {
                    this.$swal({
                      title: 'Գործողությունը մերժվել է',
                      text: '',
                      icon: 'error',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                  })
            }
          })
    },
    getNumbersInfo (checkup) {
      this.$vs.loading()
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getCheckupNumbers', checkup._id)
          .then((response) => {
            this.$vs.loading.close()
            this.$bvModal.show('modal-add-c1-number')
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },

    openHistory (checkup) {
      this.$vs.loading()
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getCheckupHistory', checkup._id)
          .then(() => {
            this.$vs.loading.close()
            this.$bvModal.show('modal-checkup-history')
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },

    openCompanyInfo(checkup) {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_CHECKUP_COMPANY', checkup)
      this.$bvModal.show('modal-checkup-company-info')
    }

  }
}
</script>
