<template>
  <section class="financial-preview-wrapper">


    <b-row class="financial-preview">

      <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
      >
        <b-card>

          <!-- Button: CLose -->
          <b-button
              variant="outline-secondary"
              class="mb-75"
              block
              @click="closeDoc"
          >
            <feather-icon
                icon="XCircleIcon"
                class="mr-50"
            />
            Փակել
          </b-button>

          <div class="radios-container mb-1">
            <b-form-radio
                name="type"
                v-model="filters.type"
                :value="'amis'"
                class="custom-control-success mb-1"
            >
              Ամիս
            </b-form-radio>
            <b-form-radio
                name="type"
                v-model="filters.type"
                :value="'kisamyak'"
                class="custom-control-success"
            >
              Կիսամյակ
            </b-form-radio>
          </div>

          <template v-if="userType === 'ADMIN' || userType === 'VIEWER'">
            <v-select
                @input="changeCompany"
                placeholder="Կազմակերպություն"
                id="vue-select"
                :reduce="prefix => prefix.id"
                v-model="filters.companies"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="companies"
                class="mb-1"
                :multiple="true"
            />
          </template>

          <v-select
              @input="updateFinanceRange"
              placeholder="Ժամանակահատված"
              id="vue-select-ranges"
              v-model="filters.year"
              :reduce="prefix => prefix.value"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="rangesSelect"
              class="mb-1"
          />

          <!-- Button: Send Invoice -->
          <b-button
              variant="success"
              class="mb-75 mt-1"
              block
              @click="getCheckups"
          >
            <feather-icon
                icon="SearchIcon"
                class="mr-50"
            />
            <span>Որոնել</span>
          </b-button>


          <!-- Button: Send Invoice -->
          <b-button
              variant="success"
              class="mb-75 mt-1"
              block
              @click="exportExcel"
          >
            <feather-icon
                icon="DownloadIcon"
                class="mr-50"
            />
            <span>Բեռնել</span>
          </b-button>

        </b-card>
      </b-col>

      <b-col
          cols="12"
          xl="9"
          md="8"
      >
        <b-card
            no-body
            class="financial-preview-card"
            ref="document"
        >
          <!-- Header -->
          <b-card-body class="pb-0">
            <table style="width: 70%;margin: 0 auto;" data-cols-width="10,30,40,20" id="headerTable"
                   class="financial-table">
              <thead>
              <tr>
                <th data-a-h="center" class="border-0" :colspan="checkups.length + 2">Հաշվետվություն ըստ ամիսների և ըստ կիսամյակի</th>
              </tr>
              <tr>
                <th data-b-a-s="thin">Հ/Հ</th>
                <th data-b-a-s="thin">Անվանումը</th>
                <th v-for="item in checkups" data-b-a-s="thin">{{ item.name }}</th>
              </tr>
              </thead>
              <tbody v-if="checkups.length > 0">
              <tr v-for="(company, companyIndex) in data">
                <td data-b-a-s="thin">{{ parseInt(companyIndex) + 1 }}</td>
                <td data-b-a-s="thin">{{ company.name }}</td>
                <td v-for="count in company.values" data-b-a-s="thin">{{ count }}</td>
              </tr>
              </tbody>
            </table>

          </b-card-body>

        </b-card>
      </b-col>


    </b-row>
  </section>
</template>

<script>

import techServiceModule from '@/store/tech-service/techServiceModule'
import { BRow, BCol, BCard, BCardBody, BButton, BFormInput, BFormRadio } from 'bootstrap-vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import store from '@/store'
import vSelect from 'vue-select'
import companyModule from '@/store/companies/companyModule'
import TableToExcel from '@linways/table-to-excel'

let todayStart = new Date()
todayStart.setHours(0, 0, 0, 0)

let todayEnd = new Date()
todayEnd.setHours(23, 59, 59, 59)

export default {
  name: 'info-doc',
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormInput,
    DateRangePicker,
    vSelect,
    BFormRadio
  },
  data() {
    return {
      financeRanges: todayStart + ' - ' + todayEnd,
      financeForm: {
        startDay: this.$options.filters.formatDayInput(todayStart),
        endDay: this.$options.filters.formatDayInput(todayEnd),
      },
      multipleValues: [1, 2],
      optionsMultiple: [
        {
          id: 1,
          label: '1 տարուց'
        },
        {
          id: 2,
          label: '2 տարուց'
        }
      ],
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      COMPANIES_STORE_MODULE_NAME: 'companiesModule',
      filters: {
        companies: [0],
        type: 'kisamyak',
        year: 0
      },
      rangesSelect: [
        {
          value: 0,
          label: 'Այս տարի'
        },
        {
          value: 1,
          label: 'Անցած տարի'
        }
      ],
      dateRange: {
        startDate: todayStart,
        endDate: todayEnd
      },
      localeData: {
        applyLabel: 'Հաստատել',
        cancelLabel: 'Չեղարկել',
        ranges: [],
        firstDay: 1,
        format: 'dd/mm/yyyy',
        customRangeLabel: 'Այլ',
        daysOfWeek: ['Կիր', 'Երկ', 'Երք', 'Չոր', 'Հնգ', 'Ուրբ', 'Շբթ'],
        monthNames: ['Հուն', 'Փետ', 'Մար', 'Ապր', 'Մայ', 'Հուն', 'Հուլ', 'Օգ', 'Սեպ', 'Հոկ', 'Նոյ', 'Դեկ'],
      },
    }
  },
  computed: {

    checkups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].report_view_data
    },

    data() {
      let data = {};
      this.checkups.map((report, index) => {
        report.data.map((item, companyIndex) => {
          if (!data[companyIndex]) {
            data[companyIndex] = {
              _id: item._id,
              name: item.name,
              values: []
            }
          }
        })
      });
      this.checkups.map((report, index) => {
        report.data.map((item, companyIndex) => {
          data[companyIndex].values.push(item.count)
        })
      })
      return data;
    },

    total_count() {
      let total = 0
      this.checkups.map(item => {
        total += item.count
      })
      return total
    },

    user() {
      return this.$store.state.user
    },

    userType() {
      return atob(this.$store.state.user_type)
    },

    companies() {
      const data = []
      const companies = this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies.map(e => ({
        ...e,
        label: e.name,
        id: e._id
      }))
      let all = {
        label: 'Բոլորը',
        id: 0
      }
      data.push(all)
      data.push(...companies)
      return data
    },

    company: {
      get() {
        if (this.userType === 'TECHNICAL_SERVICE_OPERATOR') {
          return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company
        } else if (this.userType === 'TECHNICAL_SERVICE_OWNER') {
          return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies[0]
        }
        return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company
      },
      set(data) {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_COMPANY', data)
      }
    },

    dateRanges() {
      // Today
      let today = new Date()

      let allStart = new Date(this.firstCheckupDate)

      let todayStart = new Date()
      let todayEnd = new Date()

      // Yesterday
      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)

      // This week
      let thisWeekStart = new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay()))
      let thisWeekEnd = new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay() + 6))

      // Last Week
      let lastWeekStart = new Date(new Date().setDate(new Date().getDate() - 6 - new Date().getDay()))
      let lastWeekEnd = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))

      // This Month
      let thisMonthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      let thisMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)

      // Last Month
      let lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1)
      let lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0)

      // This Quarter
      let currentMonth = today.getMonth()
      let currentYear = today.getFullYear()
      let thisQuarterStart
      let thisQuarterEnd
      let lastQuarterStart
      let lastQuarterEnd
      if (currentMonth >= 0 && currentMonth < 3) {
        thisQuarterStart = new Date(today.getFullYear(), 0, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 2, 31)

        lastQuarterStart = new Date(today.getFullYear() - 1, 9, 1)
        lastQuarterEnd = new Date(today.getFullYear() - 1, 11, 31)
      } else if (currentMonth >= 3 && currentMonth < 6) {
        thisQuarterStart = new Date(today.getFullYear(), 3, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 5, 30)

        lastQuarterStart = new Date(today.getFullYear(), 0, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 2, 31)
      } else if (currentMonth >= 6 && currentMonth < 9) {
        thisQuarterStart = new Date(today.getFullYear(), 6, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 8, 30)

        lastQuarterStart = new Date(today.getFullYear(), 3, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 5, 30)
      } else if (currentMonth >= 9 && currentMonth < 12) {
        thisQuarterStart = new Date(today.getFullYear(), 9, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 11, 31)

        lastQuarterStart = new Date(today.getFullYear(), 3, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 5, 30)
      }

      let thisSemesterStart
      let thisSemesterEnd
      let lastSemesterStart
      let lastSemesterEnd
      if (currentMonth >= 0 && currentMonth < 5) {
        thisSemesterStart = new Date(today.getFullYear(), 0, 1)
        thisSemesterEnd = new Date(new Date().getFullYear(), 5, 30)

        lastSemesterStart = new Date(today.getFullYear() - 1, 6, 1)
        lastSemesterEnd = new Date(today.getFullYear() - 1, 11, 31)

      } else {
        thisSemesterStart = new Date(today.getFullYear(), 6, 1)
        thisSemesterEnd = new Date(new Date().getFullYear(), 11, 31)

        lastSemesterStart = new Date(today.getFullYear(), 0, 1)
        lastSemesterEnd = new Date(today.getFullYear(), 5, 30)
      }

      // This Year
      let thisYearStart = new Date(new Date().getFullYear(), 0, 1)
      let thisYearEnd = new Date(new Date().getFullYear(), 11, 31)

      // Last Year
      let lastYearStart = new Date(new Date().getFullYear() - 1, 0, 1)
      let lastYearEnd = new Date(new Date().getFullYear() - 1, 11, 31)

      return {
        'Այսօր': [todayStart, todayEnd],
        'Երեկ': [yesterdayStart, yesterdayEnd],
        'Այս շաբաթ': [thisWeekStart, thisWeekEnd],
        'Անցած շաբաթ': [lastWeekStart, lastWeekEnd],
        'Այս ամիս': [thisMonthStart, thisMonthEnd],
        'Անցած ամիս': [lastMonthStart, lastMonthEnd],
        'Այս եռամսյակ': [thisQuarterStart, thisQuarterEnd],
        'Անցած եռամսյակ': [lastQuarterStart, lastQuarterEnd],
        'Այս կիսամյակ': [thisSemesterStart, thisSemesterEnd],
        'Անցած կիսամյակ': [lastSemesterStart, lastSemesterEnd],
        'Այս տարի': [thisYearStart, thisYearEnd],
        'Անցած տարի': [lastYearStart, lastYearEnd],
        'Բոլորը': [allStart, todayEnd],
      }
    },

    // rangesSelect() {
    //   const data = []
    //   for (let key in this.dateRanges) {
    //     let temp = {}
    //     temp.label = key
    //     let start = this.dateRanges[key][0]
    //     start.setHours(0, 0, 0, 0)
    //     let end = this.dateRanges[key][1]
    //     end.setHours(23, 59, 59, 59)
    //     temp.value = start + ' - ' + end
    //     data.push(temp)
    //   }
    //   return data
    // },

    totalSum() {
      return this.checkups.map(item => item.Sum)
          .reduce((prev, curr) => prev + curr, 0)
    },

    excel_name() {
      return 'Հաշվետվություն.xlsx'
    }

  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.COMPANIES_STORE_MODULE_NAME)) store.registerModule(this.COMPANIES_STORE_MODULE_NAME, companyModule)
    this.getCheckups()
    this.getCompanies()
  },

  destroyed() {
    this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_DOC', 'info')
  },

  methods: {

    getCompanies() {
      if (this.userType === 'TECHNICAL_SERVICE_OWNER' || this.userType === 'ADMIN' || this.userType === 'VIEWER') {
        this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/getCompanies')
      }

    },

    closeDoc() {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_DOC', 'reportView')
    },

    updateFinanceRange() {
      let dates = this.financeRanges
      dates = dates.split(' - ')
      let startDate = dates[0]
      let endDate = dates[1]
      this.financeForm = {
        startDay: this.$options.filters.formatDayInput(startDate),
        endDay: this.$options.filters.formatDayInput(endDate),
      }

      this.getCheckups()
    },

    getCheckups() {
      this.$vs.loading()
      const filters = JSON.parse(JSON.stringify(this.filters))
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getReportViewCheckups',  this.serialize(filters))
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },

    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },

    changeCompany() {
      if (this.filters.company.length > 1) {
        const all_index = this.filters.company.findIndex(c => c === 0)
        if (all_index > -1) {
          this.filters.company.splice(all_index, 1)
        }
      } else if (this.filters.company.length === 1) {
        if (this.filters.company.includes(0)) {
          this.filters.company = [0]
        }
      }
      const index = this.companies.findIndex(e => e.id === this.filters.company)
      if (index >= 0) {
        this.company = this.companies[index]
      }
      this.getCheckups()
    },

    exportExcel() {
      if (this.userType === 'VIEWER') {
        const companies = []
        this.filters.company.map(company => {
          let index = this.companies.findIndex(c => c.id === company)
          if (index > -1) {
            companies.push(this.companies[index].label)
          }
        })
        let companiesName = ''
        companies.map((item, index) => {
          companiesName += item
          if (index !== companies.length - 1) {
            companiesName += ', '
          }
        })
        let dateTimeStart = new Date(this.financeForm.startDay)
        let dateTimeEnd = new Date(this.financeForm.endDay)
        dateTimeStart = this.$options.filters.formatDay(dateTimeStart)
        dateTimeEnd = this.$options.filters.formatDay(dateTimeEnd)
        if (companies.length === 1) {
          if (companies.includes('Բոլորը')) {
            companiesName = 'բոլոր կազմակերպությունների'
          } else {
            companiesName += ' կազմակերպության'
          }
        } else {
          companiesName += ' կազմակերպությունների'
        }
        let message = `${this.user.name} դիտորդը(${this.user.email}), ներբեռնել է ${companiesName} տեղեկատվության հաշվետվույունը, ${dateTimeStart} - ${dateTimeEnd} ժամանակահատվածի համար։`
        let data = {
          notificationType: 'NEW_MESSAGE',
          title: 'Տեղեկատվության ներբեռնում',
          message,
          data: {
            user: this.user,
            companies: companies,
            dates: {
              start: dateTimeStart,
              end: dateTimeEnd
            }
          }
        }
        this.$store.dispatch('addNotification', data)
            .then(response => {
              response.data.map((notification) => {
                // this.$socket.emit('SEND_NOTIFICATION', notification)
              })
            })
      }
      TableToExcel.convert(document.getElementById('headerTable'), {
        name: this.excel_name,
        sheet: {
          name: 'Ֆինանսական հաշվետվություն'
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.financial-preview-card {
  max-height: calc(var(--vh, 1vh) * 100 - 10rem);
  min-height: calc(var(--vh, 1vh) * 100 - 10rem);
  overflow-y: scroll;

  * {
    color: #000
  }
}

.vue-daterange-picker {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.vue-daterange-picker .show-ranges.hide-calendars {
  width: 250px !important;
  min-width: 250px !important;
}

.financial-table {
  width: 100%;
  color: #000;

  thead, tbody {
    tr {
      opacity: 1;

      th, td {
        border: 1px solid #000;
        line-height: 10px;
        height: 25px;
        text-align: center;

      }
    }
  }
}
</style>
