<template>
  <div>
    {{ params.value }}
    <fa-icon @click="openWhatsapp(params.value)" class="ml-2 cursor-pointer" icon="link" />
  </div>
</template>

<script>

import { BBadge } from 'bootstrap-vue'
import VueCountdown from '@chenfengyuan/vue-countdown';
import store from '@/store'
import techServiceModule from '@/store/tech-service/techServiceModule'

export default {
  name: 'cell-renderer-can-edit',
  components: {
    BBadge,
    VueCountdown
  },
  data() {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
    }
  },
  methods: {
    openWhatsapp (phone) {
      phone = phone.replace('(', '')
      phone = phone.replace(')', '')
      phone = phone.replace('-', '')
      phone = phone.replace('-', '')
      phone = phone.replace(' ', '')
      phone = this.addCountryCode(phone)
      window.open(`https://wa.me/${phone}`, '_blank')
    },

    addCountryCode(inputString) {
      if (inputString.length > 0) {
        return "+374" + inputString.slice(1);
      } else {
        return "+374"; // If the input string is empty, just return "+374"
      }
    }
  }
}
</script>
